.JobWindow {
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: circle();

  &.hasLink,
  &.hasLink * {
    cursor: pointer;
  }


  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
    transition: opacity .2s ease-in;
  }

  .title {
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    font-weight: 700;
    text-shadow: 0 1px 0 white, 0 -1px 0 white, 1px 0 0 white, -1px 0 0 white;
    opacity: 1;
    transition: opacity .2s ease-in;
  }
}