@import '../../sass/main';

.SectionNavigation {
  position: fixed;
  z-index: 10;
  top: 4rem;
  left: 2rem;
  color: black;
  transition: color .2s ease-in;

  @include respond(phone) {
    top: 1rem;
    left: 1rem;
  };

  &.inTop {
    color: white;

    .leftline { background-color: white; }
    .label {
      &::before {
        background-color: white;
      }
    }
  }
  

  .leftline {
    position: absolute;
    width: 1px;
    background-color: black;
    left: 5px;
    top: 0.6rem;
    bottom: 0.6rem;
    display: flex;
    justify-content: center;
    transition: background-color .2s ease-in;
  }

  .symbol {
    position: absolute;
    // top: 0%;
    width: 13px;
    height: 13px;
    clip-path: circle();
    background-color: $color-primary;
    transform: translateY(-0.4rem);
    transition: top .5s ease-in-out;
  }

  .label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: '';
      width: 10px;
      height: 1px;
      background-color: black;
      display: inline-block;
      margin-right: 0.6rem;
      transition: background-color .2s ease-in;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;

      @include respond(phone) {
        margin-bottom: 1rem;
      };
    }
  }
}