.HomeTop {
  position: relative;
  // height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #canvas {
    position: relative;
  }

  .content {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;

    h1 {
      margin-bottom: 1rem;
    }
  }
}