.HomePortfolios {
  text-align: center;
  padding: 6rem 1rem;
  
  .works {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    > * {
      margin: 0 .6rem .6rem .6rem;
    }
  }
}