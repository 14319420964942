@import '../../sass/main';

.Footer {
  text-align: center;
  padding: 2rem 1rem;

  a,
  a:link,
  a:active,
  a:visited {
    text-decoration: none;
    color: unset;
  }

  .icons {
    margin-bottom: 1rem;

    > .icon:not(:last-child) {
      margin-right: .4rem;
    }

    .icon {
      cursor: pointer;
      transition: .1s ease-in;

      &:hover {
        fill: $color-primary;
      }
    }
  }
  
  .copyright {
    font-size: .8rem;
    color: #555;
  }
}