.HomeSkills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 1rem;

  .row {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .icon-container {
    width: 5rem;
    text-align: center;
    margin: .4rem;
    
    .icon {
      width: 100%;
    }

    p {
      font-size: .8rem;
      color: #555;
      margin-top: .2rem;
    }
  }
}