@import './variables';

%heading {
  color: 'black';
}

.heading-1 {
  @extend %heading;
  font-size: 2.925rem;
  line-height: 1;

  @include respond(phone) {
    font-size: 2.4rem;
  }
}

.heading-2 {
  @extend %heading;
  font-size: 1.95rem;
  line-height: 1;
}

.heading-3 {
  @extend %heading;
  font-size: 1.625rem;
  text-transform: uppercase;
}

.heading-4 {
  @extend %heading;
  font-size: 1.235rem;
}