@import '../../sass/main';

.SectionTitle {
  text-align: center;

  .border {
    display: block;
    margin: 1rem auto;
    height: 2px;
    max-width: 10rem;
    width: 100%;
    border-radius: 3px;
    background-color: $color-primary;
  }
}